<template>
  <content-pane>
    <v-row dense>
      <v-col><h1>Over deze app</h1></v-col>
    </v-row>
    <v-row dense>
      <v-col>
        Het platform GAON biedt inwoners, bezoekers, ondernemers, werkgevers en
        werknemers kansen om slim, duurzaam en gezond door de Achterhoek te
        reizen. GAON wil de aantrekkingskracht van de regio versterken door de
        bereikbaarheid te verbeteren en de maatschappelijke negatieve effecten
        van mobiliteit te beperken, door in te spelen op andere vormen van
        duurzame mobiliteit.
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        De GAON app is ontwikkeld vanuit het project Netmobiel en wordt
        doorontwikkeld en onderhouden door
        <external-link
          href="https://baseflow.com/"
          @link-disabled="onBlockedLink"
        >
          <template #anchorBody><strong>Baseflow</strong></template>
        </external-link>
        uit Enschede. in opdracht van de Achterhoek. Meer informatie vind je op
        <external-link
          href="https://www.gaon.nl/"
          @link-disabled="onBlockedLink"
        >
          <template #anchorBody><strong>onze website</strong></template>
        </external-link>
      </v-col>
    </v-row>
    <external-link-blocked-dialog
      :website="website"
      :show-dialog="dialogIsVisible"
      @close="dialogIsVisible = false"
    />
  </content-pane>
</template>

<script>
import ContentPane from '@/components/common/ContentPane'
import * as uiStore from '@/store/ui'
import ExternalLinkBlockedDialog from '@/components/dialogs/ExternalLinkBlockedDialog'
import ExternalLink from '@/components/common/ExternalLink'

export default {
  name: 'AboutPage',
  components: { ExternalLink, ContentPane, ExternalLinkBlockedDialog },
  data() {
    return {
      dialogIsVisible: false,
      website: '',
    }
  },
  mounted() {
    uiStore.mutations.showBackButton()
  },
  methods: {
    onBlockedLink(link) {
      this.website = link
      this.dialogIsVisible = true
    },
  },
}
</script>

<style scoped></style>
