import { render, staticRenderFns } from "./ConversationPage.vue?vue&type=template&id=661eb73b&scoped=true&"
import script from "./ConversationPage.vue?vue&type=script&lang=js&"
export * from "./ConversationPage.vue?vue&type=script&lang=js&"
import style0 from "./ConversationPage.vue?vue&type=style&index=0&id=661eb73b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "661eb73b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VContainer,VDivider,VIcon,VProgressCircular,VRow,VTextarea})
