import { render, staticRenderFns } from "./TripConfirmedPage.vue?vue&type=template&id=21ba65da&scoped=true&"
import script from "./TripConfirmedPage.vue?vue&type=script&lang=js&"
export * from "./TripConfirmedPage.vue?vue&type=script&lang=js&"
import style0 from "./TripConfirmedPage.vue?vue&type=style&index=0&id=21ba65da&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ba65da",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VChip,VCol,VRow,VTextarea})
