var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-pane',{attrs:{"scrollable":""},on:{"low":_vm.onLowWater},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.isDrivingPassenger)?_c('tab-bar',{staticClass:"shrink",attrs:{"selected-tab-model":_vm.selectedTab},on:{"tabChange":function (n) { return (_vm.selectedTab = n); }},scopedSlots:_vm._u([{key:"firstTab",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v("directions_car")]),_c('span',[_vm._v(" Chauffeur "),(_vm.ridesSearchTime === 'Future' && _vm.plannedRides.totalCount > 0)?_c('sup',[_vm._v(_vm._s(_vm.plannedRides.totalCount))]):_vm._e()])]},proxy:true},{key:"secondTab",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v("commute")]),_c('span',[_vm._v(" Passagier "),(_vm.tripsSearchTime === 'Future' && _vm.plannedTrips.totalCount > 0)?_c('sup',[_vm._v(_vm._s(_vm.plannedTrips.totalCount))]):_vm._e()])]},proxy:true}],null,false,3542177795)}):_vm._e()]},proxy:true}])},[(_vm.isPassengerView)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-1"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-radio-group',{staticClass:"mt-1",attrs:{"row":""},model:{value:(_vm.tripsSearchTime),callback:function ($$v) {_vm.tripsSearchTime=$$v},expression:"tripsSearchTime"}},[_c('v-radio',{attrs:{"label":"Afgelopen ritten","value":"Past"}}),_c('v-radio',{attrs:{"label":"Geplande ritten","value":"Future"}})],1)],1)],1),(_vm.tripsSearchTime === 'Past')?_c('v-row',{attrs:{"dense":""}},[(_vm.pastTrips.totalCount === 0)?_c('v-col',{attrs:{"align":"center"}},[_c('em',[_vm._v("Je hebt nog geen ritten gemaakt.")])]):_c('v-col',{staticClass:"py-0"},[_c('grouped-card-list',{attrs:{"items":_vm.pastTrips.data,"get-date":function (t) { return t.itinerary.arrivalTime; }},scopedSlots:_vm._u([{key:"card",fn:function(ref){
var trip = ref.item;
return [_c('travel-card',{staticClass:"trip-card",attrs:{"trip-id":trip.id,"trip-state":trip.state,"itinerary":trip.itinerary,"relative-time":false},on:{"on-trip-selected":_vm.onTripSelected}})]}}],null,false,1013562544)})],1)],1):_vm._e(),(_vm.tripsSearchTime === 'Future')?_c('v-row',{attrs:{"dense":""}},[(_vm.plannedTrips.totalCount === 0)?_c('v-col',[_vm._v(" Je hebt geen bewaarde ritten. Ga naar de planner om een rit te plannen. ")]):_c('v-col',{staticClass:"py-0"},[_c('grouped-card-list',{attrs:{"items":_vm.plannedTrips.data,"get-date":function (t) { return t.itinerary.arrivalTime; }},scopedSlots:_vm._u([{key:"card",fn:function(ref){
var trip = ref.item;
return [_c('travel-card',{staticClass:"trip-card",attrs:{"trip-id":trip.id,"trip-state":trip.state,"itinerary":trip.itinerary,"relative-time":false},on:{"on-trip-selected":_vm.onTripSelected}})]}}],null,false,1013562544)})],1)],1):_vm._e()],1)],1):_vm._e(),(_vm.isDriverView)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-1"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-radio-group',{staticClass:"mt-1",attrs:{"row":""},model:{value:(_vm.ridesSearchTime),callback:function ($$v) {_vm.ridesSearchTime=$$v},expression:"ridesSearchTime"}},[_c('v-radio',{attrs:{"label":"Afgelopen ritten","value":"Past"}}),_c('v-radio',{attrs:{"label":"Geplande ritten","value":"Future"}})],1)],1)],1),(_vm.ridesSearchTime === 'Past')?_c('v-row',{attrs:{"dense":""}},[(_vm.pastRides.totalCount === 0)?_c('v-col',[_c('span',[_vm._v(" Je hebt nog geen ritten gereden. Ga naar de planner om een nieuwe rit te plannen. ")])]):_c('v-col',{staticClass:"py-0"},[_c('grouped-card-list',{attrs:{"items":_vm.pastRides.data,"get-date":function (r) { return r.departureTime; }},scopedSlots:_vm._u([{key:"card",fn:function(ref){
var ride = ref.item;
var index = ref.index;
return [_c('ride-card',{staticClass:"trip-card",attrs:{"index":index,"ride":ride,"relative-time":false},on:{"rideSelected":_vm.onRideSelected}})]}}],null,false,777254179)})],1)],1):_vm._e(),(_vm.ridesSearchTime === 'Future')?_c('v-row',{attrs:{"dense":""}},[(_vm.plannedRides.totalCount === 0)?_c('v-col',{staticClass:"py-1"},[_c('span',[_vm._v(" Je hebt nog geen ritten gepland. Ga naar de planner om een nieuwe rit te plannen. ")])]):_c('v-col',{staticClass:"py-0"},[_c('grouped-card-list',{attrs:{"items":_vm.plannedRides.data,"get-date":function (r) { return r.departureTime; }},scopedSlots:_vm._u([{key:"card",fn:function(ref){
var ride = ref.item;
var index = ref.index;
return [_c('ride-card',{staticClass:"trip-card",attrs:{"index":index,"ride":ride,"relative-time":false},on:{"rideSelected":_vm.onRideSelected}})]}}],null,false,777254179)})],1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }