<template>
  <v-card class="rounded-border">
    <v-card-title>Algemene voorwaarden</v-card-title>
    <v-card-text class="py-0">
      <v-form ref="form" v-model="valid">
        <v-row vertical-align-center>
          <v-col>
            <p>
              De GAON app is in opdracht van de Achterhoek door Baseflow
              ontwikkeld als multimodale app waarmee je jouw reis binnen (en
              straks ook buiten) de Achterhoek kunt plannen, boeken en betalen.
              Op dit moment biedt de app 2 mogelijkheden aan:
            </p>
            <ul>
              <li>Aanbieden en deelnemen aan gedeelde ritten;</li>
              <li>Plannen van een openbaar vervoer reis.</li>
            </ul>
            <p>
              Ook kan de reisplanner een gecombineerde reis maken met een
              gedeelde rit en het OV. In de komende tijd wordt dit uitgebreid
              met (elektrische) deelfietsen, elektrische deelauto’s en
              taxiritten in de Achterhoek.
            </p>
            <p>
              De app wordt onderhouden door Baseflow in Enschede. Vereniging
              GAON Ritdelen (voorheen Vereniging Netmobiel) is verantwoordelijk
              voor de uitbetalingen. Denk hierbij aan de vergoeding die je kunt
              krijgen als je een rit aanbiedt. Ook stelt Vereniging GAON
              Ritdelen bepaalde gedragsregels op om het reizen met GAON voor
              zowel chauffeur als passagier veilig en comfortabel te houden. In
              dat kader is het verplicht om (gratis) lid te worden van
              Vereniging GAON Ritdelen, voordat je gebruik kunt maken van deze
              GAON-app. In een later stadium, wanneer bijvoorbeeld ook
              deelauto’s of deelfietsen zijn toegevoegd, zal dit optioneel
              worden.
            </p>
            <p>
              Nadere informatie over de verwerking van persoonsgegevens binnen
              de GAON app en de Vereniging GAON Ritdelen is terug te vinden in
              de
              <external-link
                href="https://gaon.nl/wp-content/uploads/2022/09/Gaon-app-privacycverklaring-gaon-app.pdf"
                @link-disabled="onBlockedLink"
              >
                <template #anchorBody
                  ><strong>privacyverklaring</strong></template
                >
              </external-link>
              .
            </p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="1">
            <v-checkbox
              v-model="consent.acceptedTerms"
              :readonly="readOnly"
              :rules="[rules.required]"
            ></v-checkbox>
          </v-col>
          <v-col cols="11">
            Ik ga hierbij akkoord met het
            <external-link
              href="https://gaon.nl/wp-content/uploads/2022/09/Gaon-app-algemene-voorwaarden.pdf"
              @link-disabled="onBlockedLink"
            >
              <template #anchorBody><strong>reglement</strong></template>
            </external-link>
            van Vereniging GAON Ritdelen (voorheen Vereniging Netmobiel) en de
            ‘Code Goed Gedrag' die gelden bij het gebruik van de GAON-app. Ik
            verklaar daarnaast kennis te hebben genomen van de
            <external-link
              href="https://gaon.nl/wp-content/uploads/2022/09/Gaon-app-privacycverklaring-gaon-app.pdf"
              @link-disabled="onBlockedLink"
            >
              <template #anchorBody
                ><strong>privacyverklaring</strong></template
              >
            </external-link>
            en geef toestemming voor de verwerking van mijn persoonsgegevens.
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters class="mb-2">
        <v-col xs6 class="mx-2">
          <v-btn block rounded outlined color="primary" @click="back()">
            <v-icon>arrow_back</v-icon>
            Terug
          </v-btn>
        </v-col>
        <v-col v-if="!readOnly" xs6 class="mx-2">
          <v-btn
            block
            rounded
            depressed
            color="button"
            :disabled="!valid"
            @click="submitForm()"
          >
            Verder
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <external-link-blocked-dialog
      :website="website"
      :show-dialog="dialogIsVisible"
      @close="dialogIsVisible = false"
    />
  </v-card>
</template>

<script>
import ExternalLink from '@/components/common/ExternalLink'
import ExternalLinkBlockedDialog from '@/components/dialogs/ExternalLinkBlockedDialog'

export default {
  name: 'NewAccountTerms',
  components: { ExternalLink, ExternalLinkBlockedDialog },
  props: {
    value: {
      type: Object,
      default: () => undefined,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      consent: { ...this.value },
      valid: false,
      rules: {
        required: (value) => !!value || '',
      },
      dialogIsVisible: false,
      website: '',
    }
  },
  methods: {
    submitForm() {
      // Only show consent error after the user has clicked on submit.
      this.validate()
      if (this.valid) {
        this.$emit('update-consent', this.consent)
        this.$emit('next-step')
      }
    },
    back() {
      this.$emit('prev-step')
    },
    validate() {
      this.$nextTick(() => {
        const isValid = this.$refs.form.validate()
        this.$emit('onFormValid', isValid)
      })
    },
    onBlockedLink(link) {
      this.website = link
      this.dialogIsVisible = true
    },
  },
}
</script>

<style scoped lang="scss"></style>
